<template>
	<div class="dashboard-container">
		<nav>
			<ul class="f-family-helvetica">
				<li>
					<router-link to="/dashboard/" exact-active-class="nav-active-item"
						>EMPRESAS</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/redenciones"
						exact-active-class="nav-active-item"
						>REDENCIONES</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/buscar-usuario"
						exact-active-class="nav-active-item"
						>USUARIOS</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/usuarios/crear"
						exact-active-class="nav-active-item"
						>CREAR USUARIO</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/usuarios"
						exact-active-class="nav-active-item"
						>ACTIVIDAD</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/estadisticas"
						exact-active-class="nav-active-item"
						>ESTADÍSTICAS</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/retos"
						exact-active-class="nav-active-item"
						>RETOS</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/validate-academy"
						exact-active-class="nav-active-item"
						class="uppercase"
						>academia</router-link
					>
				</li>
				<!-- <li>
          <router-link
            to="/dashboard/retos-dcm-p05"
            exact-active-class="nav-active-item"
            >RETOS DCM</router-link
          >
        </li> -->
				<li>
					<router-link
						to="/dashboard/reportes"
						exact-active-class="nav-active-item"
						>REPORTES</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/redenciones-puntos"
						exact-active-class="nav-active-item"
						>REDENCIONES RETOS</router-link
					>
				</li>
				<!-- <li>
          <router-link
            to="/dashboard/redenciones-dcm"
            exact-active-class="nav-active-item"
            >REDENCIONES DCM</router-link
          >
        </li> -->
				<li>
					<router-link
						to="/dashboard/listar-compras"
						exact-active-class="nav-active-item"
						>COMPRAS</router-link
					>
				</li>
				<li>
					<router-link
						to="/dashboard/premios"
						exact-active-class="nav-active-item"
						>PREMIOS</router-link
					>
				</li>
				<!-- <li>
          <router-link
            to="/dashboard/redenciones-cs"
            exact-active-class="nav-active-item"
            >REDENCIONES EN</router-link
          >
        </li> -->
			</ul>
		</nav>
		<router-view></router-view>
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.dashboard-container {
	background-image: url("../../assets/images/background_dt.png");
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	padding: 2rem 0;
	position: relative;
	display: flex;
	flex-direction: column;
	font-family: "Nunito";

	nav {
		width: 90%;
		align-self: center;

		ul {
			padding: 0;
			margin-top: 2rem;
			list-style: none;
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: 2rem;
			justify-items: center;

			li {
				a {
					color: #f9b902;
					padding: 1rem;
				}

				.nav-active-item {
					background: white;
				}
			}
		}
	}
}
</style>
